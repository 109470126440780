import React from "react";
import { BsCaretRightFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";

function Card(props) {
  const { strings, currentLanguageCode } = props;
  const { title, text, date, image, textTr, titleTr } = props.item;
  const navigate = useNavigate();
  return (
    <div
      className="card d-flex w-100 rounded"
      onClick={() =>
        navigate(`/news_detail/${title.replace(/\s/g, "_").toLowerCase()}`)
      }
    >
      <div className="triangle"></div>
      <div className="triangle"></div>
      <div className="triangle"></div>
      <div
        style={{ maxHeight: "200px", width: "100%" }}
        className="bg-light card-img rounded overflow-hidden"
      >
        <img src={image} alt="img" width="100%" />
      </div>
      <div className="text-container mt-5">
        <div className="title-text ">
          <h3
            className="first-letter"
            style={{ color: "black", fontSize: "20px" }}
          >
            {titleTr}
          </h3>
          <span className="date mb-1">{date}</span>
        </div>
        <p className="card-text">
          { textTr.substring(0, 100)
           
        }
        </p>{" "}
        <div className="animation-button">
          <Link to={`/news_detail/${title}`}>
            <button className="btn">
              <BsCaretRightFill className="arrow1" />
              &nbsp; Daha Fazla Oku
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default connect()(multilanguage(Card));
