import React, { useEffect, useRef } from "react";
import "../../assets/css/components/VideoHome/VideoHome.css";

export default function VideoHome({ video }) {
  return (
    <video

    className="d-block video_home rounded shadow-sm"

      autoPlay={true}
      loop
      muted
      // webkit-playsInLine
      playsInline
    >
      <source src={video} type="video/mp4" />
    </video>
  );
}
