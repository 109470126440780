import React from "react";
import "../../assets/css/components/CardList/style.css";
import Card from "./Card";
import newData from "../../data/newsData.json";

export default function CardList() {
  return (
    <section className="card-wrapper d-flex align-items-center py-5">
      <div className="container-lg">
        <div className="card-list row my-0 ">
          <div className="col-sm-6 col-md-5 offset-md-1 col-lg-3 offset-lg-0">
            <Card item={newData[0]} />
          </div>
          <div className="col-sm-6 col-md-5 col-lg-3 offset-lg-0">
            <Card item={newData[1]} />
          </div>
          <div className="col-sm-6  col-md-5 offset-md-1 col-lg-3 offset-lg-0 mt-1 mt-md-3 mt-lg-0">
            <Card item={newData[2]} />
          </div>
          <div className="col-sm-6  col-md-5 col-lg-3 offset-lg-0 mt-1 mt-md-3 mt-lg-0">
            <Card item={newData[3]} />
          </div>
        </div>
      </div>
    </section>
  );
}
