import React, { useEffect } from "react";
import { useState } from "react";
import Cookies from "js-cookie";
import "../../assets/css/components/Cookie/cookie.css";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";

function Cookie(props) {
  const { strings, currentLanguageCode } = props;
  const [cl, setCl] = useState("d-none");

  const saveCookie = () => {
    Cookies.set("cookie_rubber", true, { expires: 3 });
    setCl("d-none");
  };

  useEffect(() => {
    if (!Cookies.get("cookie_rubber")) setCl("");
  }, []);

  return (
    <div className={"cookie " + cl}>
      <div className="first-part"></div>
      <div className="second-part">
        <div className="d-flex h-100 justify-content-center align-items-lg-center align-items-start flex-lg-row flex-column  container">
          <p className="cookie-text mt-3">
            {
              strings[
                "This site uses cookies to serve you better. For detailed information, you can review our "
              ]
            }
            <a href="">{strings["KVKK Metni"]}</a>{" "}
            {currentLanguageCode == "tr" && "mizi inceleyebilirsiniz."}
          </p>
          <button className="btn btn-danger btn-sm" onClick={saveCookie}>
            {strings["I have read, understood and approved."]}
          </button>
        </div>
      </div>
    </div>
  );
}

export default connect()(multilanguage(Cookie));
