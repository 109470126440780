import React, { useState } from "react";
import "../../../assets/css/components/Headers/components/NavMini/navMini.css";
import Accordion from "../../Accordion/Accordion";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import Social from "../../Social/Social";
import { multilanguage, changeLanguage } from "redux-multilanguage";
import { connect } from "react-redux";

function NavMini({ strings, srcImage, dispatch, currentLanguageCode }) {
  console.log(strings);
  /*   const { srcImage } = props; */
  const options = [
    {
      title: "Ürünler",
      subTitles: [
       
        "perno takozlar",
        "bronz yataklar",
        "kaplinler",
        "tork statörleri",
        "pompalar",
        "motor contaları",
        "takozlar",
        "kabin kabloları",
        "kabin takozları",
        "yataklar",
        "damperler",
        "kitler",
        "toz keçeleri",
        "keçeler",
        "filtre camları",
        "depo kapakları",
        "kasnaklar",
        "fanlar",
        "operatör koltukları",
        "burçlar",
        "süspansiyon takozları",
        "plastik yataklar",
        "makas takozları",
        "dişliler",
        "şanzıman takozları",
        "plastik pullar",
        "vibrasyon takozları",
        "valfler",
        "tesisat kabloları",
        "tork konvertörler",
        "kasnak gergileri",
        "radyatör contaları",
        "flanşlı kaplinler",
      ],
    },
  ];

  const optionsAccordion = options.map((option) => (
    <Accordion title={option.title} id={uuidv4()}>
      <ul>
        {option.subTitles?.map((sub) => (
          <li className="py-0">
            <Link
              to={
                option.title == "Ürünler" && sub !== "products"
                  ? "/products/" + sub.replace(/\s/g, "_").toLowerCase()
                  : "/" + sub.replace(/\s/g, "_").toLowerCase()
              }
            >
              {sub}
            </Link>
          </li>
        ))}
      </ul>
    </Accordion>
  ));

  return (
    <div className="nav-mini d-xl-none col-10 offset-1 col-lg-8 offset-lg-2 list h-100">
      <Link to="/">
        <p className="page-title">{strings["Anasayfa"]}</p>
      </Link>
      <Accordion title={strings["About Us"]} id={uuidv4()}>
        <ul>
          {[
            strings["About Us"],
            strings["Who We Are"],
            strings["Research & Development"],
            strings["Packaging & Delivery"],
            strings["Warehouse"],
          ].map((sub) => (
            <li className="py-0">
              <Link
                to={
                  sub === "About Us"
                    ? "/about_us"
                    : "/about_us#" + sub.replace(/\s/g, "_").toLowerCase()
                }
              >
                {sub}
              </Link>
            </li>
          ))}
        </ul>
      </Accordion>
      {optionsAccordion}
      <Accordion title={strings["Media"]} id={uuidv4()}>
        <ul>
          {[
            strings["Media"],
            strings["Download"],
            strings["News"],
            strings["Gallery"],
            strings["Social Media"],
          ].map((sub) => (
            <li className="py-0">
              <Link
                to={
                  sub === "Media"
                    ? "/media"
                    : "/media/#" + sub.replace(/\s/g, "_").toLowerCase()
                }
              >
                {sub}
              </Link>
            </li>
          ))}
        </ul>
      </Accordion>
      <Accordion title={[strings["Contact"]]} id={uuidv4()}>
        <ul>
          <li>
          <Link to="/contact">{[strings["Contact_Us"]]}</Link>
          </li>
        </ul>
      
      </Accordion>
      <Accordion title={[strings["Info"]]} id={uuidv4()}>
        <ul>
          <li>
            <Link to="/privacy_policy/KVKK_Politikası">
            KVKK Politikası
            </Link>
          </li>
          <li>
            <Link to="/privacy_policy/kisisel_veri_sahibi_ilgili_kisi_basvuru_ve_yanıt_sürecleri">
              KVKK
            </Link>
          </li>
          <li>
            <Link to="/privacy_policy/KVKK_saklama_ve_imha_politikası">
            KVKK Saklama ve İmha Politikası
            </Link>
          </li>
        </ul>
      </Accordion>

      {/*    <Accordion title={strings["Info"]} id={uuidv4()}>
        <ul>
          {[
            "kisisel_veri_sahibi_ilgili_kisi_basvuru_ve_yanıt_sürecleri",
            "KVKK",
            
            "KVKK Saklama ve İmha Politikası",
          ].map((sub) => (
            <li className="py-0">
              <Link
                to={
                  sub == "privacy_policy"
                    ? "/privacy_policy"
                    : "/privacy_policy/" + sub.replace(/\s/g, "_").toLowerCase()
                }
              >
                {strings[sub]}
              </Link>
            </li>
          ))}
        </ul>
      </Accordion> */}
      <Accordion title={strings["The Group"]} id={uuidv4()}>
        <ul>
          <li className="py-0">
            <a href="https://gokcekgrup.com/" target="_blank">
              {strings["Gökçek Group"]}{" "}
            </a>
          </li>
          <li>
            <a href="https://magrubber.com/" target="_blank">
              MAG Rubber
            </a>
          </li>
          <li>
            <a href="https://www.mag-mp.com/" target="_blank">
              MAG Manufacturing
            </a>
          </li>
          <li>
            <a href="https://www.magelektronik.com/" target="_blank">
              MAG Electronics
            </a>
          </li>

          <li>
            <a href="https://www.magstore.app" target="_blank">
              MAG Store
            </a>
          </li>
        </ul>
      </Accordion>
      {/* <div className="d-flex  justify-content-center  icons mt-4">
        <a href="https://www.facebook.com/magrubber/" target="_blank">
          <svg viewBox="0 0 32 32">
            <path d="M17.525,9H14V7c0-1.032,0.084-1.682,1.563-1.682h1.868v-3.18C16.522,2.044,15.608,1.998,14.693,2 C11.98,2,10,3.657,10,6.699V9H7v4l3-0.001V22h4v-9.003l3.066-0.001L17.525,9z" />
          </svg>
        </a>
        <a href="https://www.instagram.com/mag.rubber/" target="_blank">
          <svg viewBox="0 0 32 32" width="24px" height="24px">
            <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z" />
          </svg>
        </a>

        <a href="https://tr.linkedin.com/company/mag-rubber" target="_blank">
          <svg viewBox="0 0 32 32" width="20px" height="20px">
            <path d="M 8.6425781 4 C 7.1835781 4 6 5.181625 6 6.640625 C 6 8.099625 7.182625 9.3085938 8.640625 9.3085938 C 10.098625 9.3085938 11.283203 8.099625 11.283203 6.640625 C 11.283203 5.182625 10.101578 4 8.6425781 4 z M 21.535156 11 C 19.316156 11 18.0465 12.160453 17.4375 13.314453 L 17.373047 13.314453 L 17.373047 11.310547 L 13 11.310547 L 13 26 L 17.556641 26 L 17.556641 18.728516 C 17.556641 16.812516 17.701266 14.960938 20.072266 14.960938 C 22.409266 14.960937 22.443359 17.145609 22.443359 18.849609 L 22.443359 26 L 26.994141 26 L 27 26 L 27 17.931641 C 27 13.983641 26.151156 11 21.535156 11 z M 6.3632812 11.310547 L 6.3632812 26 L 10.923828 26 L 10.923828 11.310547 L 6.3632812 11.310547 z" />
          </svg>
        </a>
      </div> */}
      <div className="mt-4">
        <Social />
      </div>

      {/*  <div className="d-flex justify-content-center languages mt-5">
        <select
          value={currentLanguageCode}
          style={{ background: "var(--black)" }}
          onChange={(e) => dispatch(changeLanguage(e.target.value))}
        >
          <option value="en">EN</option>
          <option value="tr">TR</option>
        </select>
      </div> */}

      <div className="text-center mt-5 mag-store-white d-flex flex-column align-items-center">
        <a
          href="
               https://magstore.app/"
          target="_blank"
        >
          <img
            src={srcImage ?? "/assets/images/mag-stor-shine-white.gif"}
            alt="mag-store-white"
            width="100"
          />
        </a>

        <div className="visit">{strings["visit now"]}</div>
      </div>
    </div>
  );
}
/* NavMini.propTypes = {
  strings: PropTypes.object,
};
 */
export default connect()(multilanguage(NavMini));
