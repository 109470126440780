import { createStore, applyMiddleware } from "redux";
import reducers from './Reducer/RootReducer'
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";

//create store
let store = createStore(
  reducers,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save()))
);

export default store;