import React, { useRef } from "react";
import { useNavigate } from "react-router";
import products2 from "../../data/products.json";
import OwlCarousel from "react-owl-carousel";
import useIntersection from "../../hooks/useIntersection";

import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";

let currentSlide = 0;
const products = JSON.parse(JSON.stringify(products2));

function CustomCarousel(props) {
  const { strings } = props;

  const cCarousel = useRef();
  const inViewport = useIntersection(cCarousel, "-20px");
  const navigate = useNavigate();

  const items2 = products.map((item) => (
    <div
      onChange={() => console.log("hey")}
      className="category-carousel-item owl-carousel-item filterable-item"
      data-bs-interval={10000}
      onClick={() =>
        navigate(`/products/${item.name.replace(/\s/g, "_").toLowerCase()}`)
      }
    >
      <div className="icon cursor-pointer">
        {/*         <img
          src={`/assets/images/products-black/${item.image}`}
          style={{ maxHeight: "250px" }}
          alt={item.image}
        /> */}
        <div
          style={{ maxHeight: "300px" }}
          dangerouslySetInnerHTML={{ __html: item.image }}
        ></div>
      </div>

      <div className="item-position-container position-relative cursor-pointer">
        <div className="item-position">
          <svg viewBox="210 300 80 80" width={15} height={15}>
            <polygon points="250,60 100,400 400,400" />
          </svg>
        </div>
        <svg
          viewBox="0 0 100 1"
          className="item-line position-absolute top-0 bottom-0 m-auto"
        >
          <line
            x1={0}
            y1={0}
            x2={100}
            y2={0}
            stroke="#393636"
            strokeWidth="0.1"
          />
        </svg>
      </div>
      <h4 className="item-lower-name mt-2 text-center">
        <span className="text product-name">{item.nameTwo}</span>
      </h4>
    </div>
  ));

  return (
    <div id="custom-carousel" className="custom-carousel-wrapper pb-2 bg-light">
      <div className="custom-carousel text-center">
        <h3 className="section-header">Ürün Yelpazesi</h3>
        <h6 className="section-header-sub px-1 px-sm-0">
        ağır makineler için en iyi çözüm
        </h6>

        <div
          ref={cCarousel}
          className="content_page overflow-hidden index_content"
        >
          <section id="home_categories">
            <OwlCarousel
              id="home-categories-carousel"
              className="categories-carousel owl-carousel owl-theme filterable-items bg-light"
              loop={true}
              items={2}
              rewind={true}
              nav={true}
              autoplay={inViewport}
              autoplayTimeout={2000}
              autoplayHoverPause={true}
              startPosition={currentSlide}
              center={true}
              lazyLoad={true}
              onTranslate={() => {
                currentSlide += 1;
              }}
              responsive={{
                992: { items: 4 },
                768: { items: 3 },
              }}
              mouseDrag={true}
              dots={false}
              sl
              navText={[
                ' <div class="mouse_scroll prev"> <div><a class="  arrow-wrapper"><span class="m_scroll_arrows three"></span><span class="m_scroll_arrows two"></span><span class="m_scroll_arrows one"></span> </a></div> </div>',
                '<div class="mouse_scroll next"> <div> <a  class="arrow-wrapper"> <span class="m_scroll_arrows one"></span> <span class="m_scroll_arrows two"></span> <span class="m_scroll_arrows three"></span> </a> </div> </div>',
              ]}
            >
              {items2}
            </OwlCarousel>
          </section>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default connect()(multilanguage(CustomCarousel));
