import React, { Fragment } from "react";
import "../../assets/css/components/Banner/style.css";

import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";

const BannerOne = (props) => {
  const { strings,currentLanguageCode } = props;
  return (
    <Fragment>
      <div className="banner-home1-bottom py-5 overflow-hidden">
        <div className="container">
          <div className="row m-0">
            <div className="col-xl-6 mb-5 ">
              <div className="row">
                <div className="col-xl-3 col-lg-2 col-md-3 col-4 offset-lg-2 offset-xl-0">
                {currentLanguageCode == "tr" ?  <img src="/assets/images/gokcek_grup.png" alt="logo" width="100%" /> :  <img src="/assets/images/logo.png" alt="logo" width="100%" />}
                 
                </div>
                <div className="offset-2"></div>
                <div className="col-sm-11 col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 offset-xl-0 col-xl-11">
                  <img
                    src="/assets/images/fabrika.png"
                    alt="Banner"
                    width="100%"
                    className="fabrika"
                  />
                </div>
              </div>
            </div>

            <div
              className="col-xl-6 col-lg-8 offset-lg-2 mt-4 mt-xl-0  col-md-10 offset-md-1 offset-xl-0 d-flex flex-column justify-content-center"
              data-wow-duration="0.5s"
              data-wow-delay="400ms"
            >
              <div className="title-text">
                <h3>
                  <i>
                    DOĞADAN
                    <span style={{ letterSpacing: "13px", marginLeft: "15px" }}>
                   
                    </span>
                    <span
                      style={{
                        letterSpacing: "15px",
                        marginLeft: "15px",
                      }}
                    >
                      <div className="text-div-main">
                        <div
                          className="text-effect-div"
                          style={{ letterSpacing: "5px" }}
                        >
                        
                        </div>

                        <div className="text-effect-div">
                          <span style={{ letterSpacing: "0px", marginLeft: "-70px" }} className="text-effect-span">GELECEĞE</span>
                        </div>
                      </div>
                    </span>
                  </i>
                </h3>
              </div>

              {/*     <p className="text-content">
               664 Solar Panels                  
              </p> 
               <p className="text-content">
               1000 kW Per Day                
              </p> 
               <p className="text-content">
               %100 Renewable Energy Production                   
              </p>  */}
              <div>
                <ul>
                  <li className="page-title">664 Güneş Paneli</li>
                  <li className="page-title">
                  Günde 1000 kW
                  </li>{" "}
                  <li className="page-title">
                  %100 Yenilenebilir Enerji Üretimi
                  </li>{" "}
                </ul>
              </div>
            </div>
          </div>
          {/* End text */}
        </div>
        {/* End container */}
      </div>
      {/* End Banner Home1 Bottom */}

      {/* End bes-sellers */}
    </Fragment>
  );
};

export default connect()(multilanguage(BannerOne));
