import React, { Component } from "react";
import "../../assets/css/components/About/style.css";
import video from "../../assets/video/video.mp4";

import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";

class App extends Component {
  render() {
    const { strings } = this.props;
    return (
      <div className="hot-deals d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 offset-xl-1 d-flex flex-column justify-content-center pt-5 pt-lg-0 mb-lg-3">
              <div className="title-text color-white ">
                <h3 className="has_proven_text">
                GÖKÇEK İŞ MAKİNALARI'NIN BENİMSEDİKLERİ
                </h3>
              </div>
              <ul>
                <li className="page-title">Kalite</li>
                <li className="page-title">Hizmet</li>
                <li className="page-title">
                Müşteri Memnuniyeti
                </li>
                <li className="page-title">
                Eksiksiz ve Zamanında Teslimat

                </li>
              </ul>
            
            </div>
            <div className="wrapper_video offset-lg-1 col-xl-3 col-6 offset-1 col-md-5 col-lg-4 mb-md-3 mb-lg-4 mt-lg-2 offset-md-0 col-sm-6 pt-lg-5 ">
              <div className="wrapper2 mt-0">
                <input className="the-checkbox" type="checkbox" />
                <div className="video">
                  <video
                    id="triangle"
                    src={video}
                    loop
                    muted
                    autoPlay
                    playsInline
                  />
                </div>
                <div className="text">
                <span data-text="">Vİdeoyu İzle</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(multilanguage(App));
