import React from "react";

export default function Accordion(props) {
  const { title, children, id, accordionId } = props;
  return (
    <div
      className="accordion"
      id={accordionId ?? "accordionPanelsStayOpenExample"}
    >
      <div className="accordion-item">
        <div className="accordion-header " id={`panelsStayOpen-heading${id}`}>
          <button
            className="accordion-button p-0 collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#panelsStayOpen-collapse${id}`}
            aria-expanded="false"
            aria-controls={`panelsStayOpen-collapse${id}`}
          >
            <p className="accordion-title page-title w-100">{title}</p>
          </button>
        </div>
        <div
          id={`panelsStayOpen-collapse${id}`}
          className="accordion-collapse collapse"
          aria-labelledby="panelsStayOpen-headingOne"
        >
          <div className="accordion-body">{children}</div>
        </div>
      </div>
    </div>
  );
}
