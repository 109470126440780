import React, { Component, lazy, Suspense } from "react";
import "./assets/css/style.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";

import Loader from "./components/Loader/Loader";

import Home from "./routes/Home";
const WorkWithUs = lazy(() => import("./routes/WorkWithUs"));
const AboutPage = lazy(() => import("./routes/AboutPage"));
const ProductsTemplate = lazy(() => import("./routes/ProductsTemplate"));
const ProductDetail = lazy(() => import("./routes/ProductDetail"));
const Media = lazy(() => import("./routes/Media"));
const NewsDetail = lazy(() => import("./routes/NewsDetail"));
const Contact = lazy(() => import("./routes/Contact"));
const PrivacyPolicy = lazy(() => import("./routes/PrivacyPolicy"));
const PageNotFound = lazy(() => import("./routes/PageNotFound"));

class App extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(
      loadLanguages({
        languages: {
          en: require("./data/Translation/english.json"),
          tr: require("./data/Translation/turkish.json"),
        },
      })
    );
  }
  render() {
    return (
      <BrowserRouter basename="/">
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/about_us"
              element={
                <Suspense fallback={<Loader />}>
                  <AboutPage />
                </Suspense>
              }
            />

            <Route
              path="/products"
              element={
                <Suspense fallback={<Loader />}>
                  <ProductsTemplate />
                </Suspense>
              }
            />
            <Route
              path="/contact"
              element={
                <Suspense fallback={<Loader />}>
                  <Contact />
                </Suspense>
              }
            />
            <Route
              exact
              path="/news_detail/:title"
              element={
                <Suspense fallback={<Loader />}>
                  <NewsDetail />
                </Suspense>
              }
            />
            <Route
              exact
              path="/products/:name"
              element={
                <Suspense fallback={<Loader />}>
                  <ProductDetail />
                </Suspense>
              }
            />
            <Route
              path="/media"
              element={
                <Suspense fallback={<Loader />}>
                  <Media />
                </Suspense>
              }
            />
            <Route
              path="/privacy_policy/:name"
              element={
                <Suspense fallback={<Loader />}>
                  <PrivacyPolicy />
                </Suspense>
              }
            />
            <Route
              path="*"
              element={
                <Suspense fallback={<Loader />}>
                  <PageNotFound />
                </Suspense>
              }
            />
            <Route
              path="/work_with_us"
              element={
                <Suspense fallback={<Loader />}>
                  <WorkWithUs />
                </Suspense>
              }
            />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default connect()(multilanguage(App));
