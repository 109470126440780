import React from "react";
import SwipeButton from "../SwipeButton";
import "../../assets/css/components/Slider/style.css";
import Video from "../Video";
import video1 from "../../assets/video/manufactur_slider_1.mp4";
import video2 from "../../assets/video/manufactur_slider_2.mp4";

import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";

function Slider(props) {
  const { strings, currentLanguageCode } = props;
  return (
    <React.Fragment>
      <div
        id="carouselExampleCaptions"
        className="carousel slide"
        data-bs-ride="carousel"
        data-bs-interval="4000"
        data-bs-pause="false"
      >
        <div className="carousel-indicators d-xl-flex  flex-column justify-content-around  align-items-center">
          <div className="d-xl-flex flex-column mb-sm-3 mb-xl-0">
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="0"
              className="active rounded-circle circle-button"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="1"
              className="rounded-circle circle-button"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="2"
              className="rounded-circle circle-button "
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="3"
              className="rounded-circle circle-button "
              aria-label="Slide 4"
            ></button>
          </div>

          <div className="d-flex flex-column">
            <p className="slide-text d-none d-xl-block mb-3">
             AŞAĞI KAYDIR
            </p>
            <div className="d-none d-xl-block">
              <SwipeButton direction="custom-carousel" />
            </div>
          </div>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active" key="15">
          <img src="/assets/images/slider/gokcek_is _makinaları.png" alt="slider-text" className="slider_img_is_mak"  />

          
          </div>
          <div className="carousel-item" key="20">
              <img src="/assets/images/slider/duz_gorsel_1.png" alt="slider-text" className="slider_img_is_mak" />
          </div>
          <div className="carousel-item" key="19">
          <Video video={video1} />
          </div>
          <div className="carousel-item" key="13">
            <img src="/assets/images/slider/slider_gokcek-02.png" alt="slider-text" className="slider_img_is_mak" />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon mag-rubber-prev-icon "
            aria-hidden="true"
          >
            <img src="/assets/images/slider/left.svg" alt="left" />
          </span>
          <span className="visually-hidden">{strings["Previous"]}</span>
        </button>
        <button
          className="carousel-control-next "
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon mag-rubber-next-icon"
            aria-hidden="true"
          >
            <img src="/assets/images/slider/right.svg" alt="right" />
          </span>
          <span className="visually-hidden">{strings["Next"]}</span>
        </button>
      </div>
      <div className="to" id="to"></div>
    </React.Fragment>
  );
}
export default connect()(multilanguage(Slider));
