import React from "react";

export default function Video({ video }) {
  return (
    <video
      className="d-block rounded shadow-sm"
      width="100%"
      height="100%"
      autoPlay={true}
      loop
      muted
      webkit-playsInLine
      playsInline={true}
    >
      <source src={video} type="video/mp4" />
    </video>
  );
}
