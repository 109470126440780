import React from "react";
import "../../assets/css/components/Loader/loader.css";

function Loader() {
  return (
    <div className="loader">
   <img src="/assets/images/favicon.png"/>
      <small>Gökçek İş Makinaları Yedek Parça</small>
    </div>
  );
}

export default Loader;
