import React, { Component } from "react";
import Layout from "../components/Layout";
import "../assets/css/components/Home/home.css";
import Slider from "../components/Slider";
import CustomCarousel from "../components/CustomCarousel";
import About from "../components/About/About";
import CardList from "../components/CardList";
import BannerOne from "../components/Banner/BannerOne";
import Demands from "../components/Demands/index";
import VideoHome from "../components/VideoHome";
import video6 from "../../src/assets/video/video_6.mp4";

import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import FrequentlyAsked from "../components/FrequentlyAsked/FrequentlyAsked";

class Home extends Component {
  render(props) {
    const { strings } = this.props;
    return (
      <Layout>
        <div className="home">
          <Slider />
          <CustomCarousel />
          <About />

      

          <section className="video-section bg-light">
            <div className="container ">
              <div className="row align-items-center justify-content-center ">
               
                <div className="col-lg-6 text-center">

                  <VideoHome video={video6} />
                </div>
              </div>
            </div>
          </section>
         
         {/*  <section className="bg-light text-center py-5 information">
            <div className="container-lg">
              <h3 className="section-header mb-3 ">
                {
                  strings[
                    "State of the Art Manufacturing Capabilities"
                  ]
                }
              </h3>
              <div className="zoom-card col-lg-8 offset-lg-2 ">
                <div className="zoom-card--image ">
                  <img
                    src="/assets/images/manufacture.png"
                    alt="engery"
                    className="rounded shadow-sm"
                    width="100%"
                  />
                </div>
              
                <div className="zoom-card--info info--2">
                  <span className="info--text"></span>

                  <div className="icon">+</div>
                  <div className="info--image">
                    <img
                      className="img2"
                      src="/assets/images/manufacture.png"
                      alt="engery"
                      // width="100%"
                    />
                  </div>
                </div>
              </div>

            

              <button
                className="btn btn-outline-dark mb-3 mt-4 shadow-sm"
                onClick={() => (window.location.pathname = "/products")}
              >
                {strings["Get More Information About Our Products"]}
              </button>
            </div>
          </section> */}
          <BannerOne />
          <section className="youtube-section d-flex justify-content-center py-5 bg-light">
            <div className="card ">
              <iframe
                src="https://www.youtube.com/embed/Vj8XQFDiXN4?rel=0&amp;autoplay=1&mute=1&loop=1&playlist=Vj8XQFDiXN4"
                className="video-stream html5-main-video"
                loop
                muted
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </section>
          <section className="news">
            <h3 className="section-header text-center mb-4 pt-4">
         Haberler
            </h3>
            <CardList />
          </section>
        </div>
      </Layout>
    );
  }
}
export default connect()(multilanguage(Home));
